<template>
    <div>
        <div class="text-center">
            <h2 class="text-sm font-bold">
                Check your inbox
            </h2>
            <p class="mt-2 text-sm leading-5">
                Enter the 6-digit code we sent to <b>{{ form.email }}</b> to finish your login.
            </p>
        </div>

        <form-container
            class="mt-6 flex flex-col items-center"
            @submit="onLogin"
            @keydown="loginErrorMessage = ''"
        >
            <div :class="{ 'login-error': loginErrorMessage }">
                <div class="grid grid-cols-6 gap-3 sm:gap-5 max-w-sm sm:max-w-[30rem]">
                    <input
                        v-for="(digit, index) in 6"
                        :id="`digit-${index}`"
                        :key="index"
                        :ref="`digit-${index}`"
                        v-model="digits[index]"
                        class="h-12 sm:h-14 text-xl text-center border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
                        type="text"
                        maxlength="1"
                        @input="onInput(index)"
                        @paste="onPaste"
                        @keydown="onKeyDown($event, index)"
                    >
                </div>

                <div v-if="loginErrorMessage" class="text-2xs font-bold text-red-700 mt-3 text-center">
                    {{ loginErrorMessage }}
                </div>
            </div>

            <div class="flex justify-center mt-8 w-full">
                <app-button
                    type="submit"
                    appearance="primary"
                    size="lg"
                    class="w-full"
                    :loading="isSubmitting"
                    :disabled="isSubmitting"
                >
                    Login
                </app-button>
            </div>
        </form-container>

        <div class="mt-8 text-center">
            <p class="text-sm">
                Didn't receive the code?
                <span v-if="timerCount" class="font-bold">Resend code in {{ timerCount }} sec.</span>
                <a v-if="!timerCount" class="link" @click="resendOTP" @keydown.enter="resendOTP">Resend</a>
            </p>
        </div>

        <div class="mt-6 pt-6 text-sm text-center border-t border-gray-200">
            <a class="link" @click="$emit('use-another-account')" @keydown.enter="$emit('use-another-account')">
                Log into a different account
            </a>
        </div>

        <app-message v-if="resendOTPErrorMessage" type="danger" class="mt-8 mx-auto text-center">
            {{ resendOTPErrorMessage }}
        </app-message>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import { FormContainer } from '@/components/form';

import {
    loginWithTwoFactorAuth,
    resendOTP,
} from '@/api/beyond';

export default {
    name: 'FormTwoFactorAuth',

    components: {
        FormContainer,
    },

    props: {
        email: {
            type: String,
            required: true,
        },

        password: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            digits: ['', '', '', '', '', ''],
            timerCount: 0,
            isSubmitting: false,
            form: {
                otp: '',
                email: this.email,
                password: this.password,
            },
            loginErrorMessage: '',
            resendOTPErrorMessage: '',
        };
    },

    created() {
        this.startTimer();
        this.$nextTick(() => this.$refs['digit-0'][0].focus());
    },

    watch: {
        timerCount: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount -= 1;
                    }, 1000);
                }
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions(['updateOrigin']),
        ...mapActions('auth', ['addAuth']),
        ...mapActions('user', ['updateUser']),

        startTimer() {
            this.timerCount = 30;
        },

        onLogin() {
            this.isSubmitting = true;
            this.loginErrorMessage = '';

            loginWithTwoFactorAuth(this.form)
                .then(response => this.onSuccess(response.data))
                .catch(error => this.onFail(error))
                .finally(() => {
                    this.isSubmitting = false;
                });
        },

        onSuccess(data) {
            console.log('Store (auth): The user was logged in successfully.', data);
            this.updateOrigin('beyond');
            this.addAuth(data);
            this.updateUser(data.user);

            setTimeout(() => {
                this.$router.replace({ name: 'beyond-events' });
            });
        },

        onFail(error) {
            console.error(error);
            this.loginErrorMessage = 'Please enter a valid 6-digit code.';
        },

        resendOTP() {
            this.isSubmitting = true;

            resendOTP(this.email)
                .then(() => this.startTimer())
                .catch(error => this.onFailResendOTP(error))
                .finally(() => {
                    this.isSubmitting = false;
                });
        },

        onFailResendOTP(error) {
            console.error(error);
            this.startTimer();
            this.resendOTPErrorMessage = 'Something went wrong. Please try again later.';
            setTimeout(() => {
                this.resendOTPErrorMessage = '';
            }, 5000);
        },

        onInput(index) {
            if (this.digits[index].length === 1) {
                if (index < 5) {
                    this.$refs[`digit-${index + 1}`][0].focus();
                } else {
                    this.$refs['digit-5'][0].blur();
                }
            }

            this.updateCode();
        },

        onPaste(event) {
            event.preventDefault();
            const pastedData = event.clipboardData.getData('text').slice(0, 6);
            this.digits = pastedData.split('');
            this.updateCode();
            this.$refs['digit-5'][0].focus();
        },

        onKeyDown(event, index) {
            if (event.key === 'Backspace' && !this.digits[index] && index > 0) {
                this.$refs[`digit-${index - 1}`][0].focus();
            }
        },

        updateCode() {
            this.form.otp = this.digits.join('');
        },
    },
};
</script>
