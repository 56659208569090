<template>
    <div class="relative flex justify-center items-center py-12 px-8 w-full h-full bg-white">
        <pwa-installation />

        <div class="w-full">
            <div class="flex flex-col items-center">
                <img src="@/assets/images/invajo-logo.svg" alt="Invajo Logo" class="w-auto h-10 text-center">

                <p class="mt-3 mb-5 text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-brand-300 to-brand-600">
                    Check-in
                </p>
            </div>

            <login-form v-if="!isTwoFactorAuthEnabled" @initialize-two-factor-auth="initializeTwoFactorAuth" />

            <form-two-factor-auth
                v-if="isTwoFactorAuthEnabled"
                class="mt-5"
                :email="email"
                :password="password"
                @use-another-account="isTwoFactorAuthEnabled = false"
            />
        </div>
    </div>
</template>

<script>
import PwaInstallation from '@/components/utils/PwaInstallation.vue';

import LoginForm from './components/LoginForm.vue';
import FormTwoFactorAuth from './components/FormTwoFactorAuth.vue';

export default {
    name: 'PageLogin',

    components: {
        PwaInstallation,
        LoginForm,
        FormTwoFactorAuth,
    },

    data() {
        return {
            email: '',
            password: '',
            isTwoFactorAuthEnabled: false,
        };
    },

    methods: {
        initializeTwoFactorAuth(event) {
            this.email = event.email;
            this.password = event.password;
            this.isTwoFactorAuthEnabled = true;
        },
    },
};
</script>
