<template>
    <form-container
        class="flex flex-col justify-center items-center"
        @submit="onLogin"
        @keydown="loginErrorMessage = ''"
    >
        <div class="w-full" :class="{ 'login-error': loginErrorMessage }">
            <div>
                <form-input
                    id="username"
                    ref="username"
                    v-model="form.username"
                    size="lg"
                    label="Username"
                    name="username"
                    type="email"
                />
            </div>

            <div class="mt-5">
                <form-input
                    id="password"
                    v-model="form.password"
                    size="lg"
                    label="Password"
                    name="password"
                    type="password"
                />

                <div class="flex justify-between mt-3.5 mx-px">
                    <div class="flex-1 text-sm font-bold text-red-700">
                        <span v-if="loginErrorMessage">{{ loginErrorMessage }}</span>
                    </div>
                </div>
            </div>

            <div class="mt-6 mx-auto w-4/5 sm:w-full">
                <div class="space-y-2 sm:space-y-0 sm:space-x-3 text-center">
                    <app-button
                        :appearance="origin === 'beyond' ? 'primary' : 'default'"
                        size="lg"
                        class="w-full sm:w-28"
                        @click="origin = 'beyond'"
                    >
                        Beyond
                    </app-button>

                    <app-button
                        :appearance="origin === 'eu' ? 'primary' : 'default'"
                        size="lg"
                        class="w-full sm:w-28"
                        @click="origin = 'eu'"
                    >
                        Classic
                    </app-button>
                </div>
            </div>
        </div>

        <div class="flex justify-center mt-10 w-full">
            <app-button
                type="submit"
                appearance="primary"
                size="lg"
                :disabled="isSubmitting"
                :loading="isSubmitting"
                class="w-full"
            >
                Log in
            </app-button>
        </div>
    </form-container>
</template>

<script>
import { mapActions } from 'vuex';

import {
    FormContainer,
    FormInput,
} from '@/components/form';

import { legacyLogin } from '@/api/legacy';
import { beyondLogin } from '@/api/beyond';

export default {
    name: 'FormLogin',

    components: {
        FormContainer,
        FormInput,
    },

    data() {
        return {
            isSubmitting: false,
            origin: 'beyond',
            form: {
                username: '',
                password: '',
            },
            loginErrorMessage: '',
        };
    },

    created() {
        this.$nextTick(() => this.$refs.username.$refs.input.focus());
    },

    computed: {
        isBeyond() {
            return this.origin === 'beyond';
        },
    },

    methods: {
        ...mapActions(['updateOrigin']),
        ...mapActions('auth', ['addAuth']),
        ...mapActions('user', ['updateUser']),

        onLogin() {
            this.isSubmitting = true;
            this.loginErrorMessage = '';

            const loginMethod = this.isBeyond
                ? beyondLogin(this.form.username, this.form.password)
                : legacyLogin(this.origin, this.form.username, this.form.password);

            loginMethod
                .then(response => this.onSuccess(response.data))
                .catch(() => this.onFail())
                .finally(() => {
                    this.isSubmitting = false;
                });
        },

        onSuccess(loginData) {
            const data = this.isBeyond
                ? loginData
                : loginData.data;

            if (data.tfa_enabled) {
                this.$emit('initialize-two-factor-auth', { email: this.form.username, password: this.form.password });
                return;
            }

            const user = this.isBeyond
                ? data.user
                : loginData.data;
            const eventsRoute = this.isBeyond
                ? { name: 'beyond-events' }
                : { name: 'legacy-events' };

            this.updateOrigin(this.origin);
            this.addAuth(data);
            this.updateUser(user);

            setTimeout(() => {
                this.$router.replace(eventsRoute);
            }, 250);
        },

        onFail() {
            this.loginErrorMessage = 'Invalid login credentials.';
        },
    },
};
</script>

<style scoped>
.login-error {
    animation: shake 1000ms linear;
}
</style>
