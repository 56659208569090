import { fetch, post } from '@/core/beyond/api';

export function beyondLogin(username, password) {
    return window.axios.post('/api/check-in/login', {
        email: username,
        password,
    });
}

export function loginWithTwoFactorAuth(data) {
    return window.axios.post('/api/two-factor-auth/login', data);
}

export function resendOTP(email) {
    return window.axios.post('/api/two-factor-auth/resend-otp', { email });
}

export function beyondLogout() {
    return window.axios.post('/api/check-in/logout');
}

export function fetchEvents(params) {
    return fetch('projects', params);
}

export function fetchTickets(eventId) {
    return fetch(`projects/${eventId}/tickets`);
}

export function fetchTicketDetails(eventId, params) {
    return fetch(`projects/${eventId}/ticket-details`, params);
}

export function fetchTicketsList(eventId) {
    return fetch(`projects/${eventId}/tickets-list`);
}

export function fetchAttendees(eventId, params) {
    return post(`projects/${eventId}/attendees-list?page=${params.page}&search=${params.search}`, { tickets: params.tickets });
}

export function fetchAttendee(eventId, attendeeId, params) {
    return fetch(`projects/${eventId}/attendees/${attendeeId}`, params);
}

export function fetchAttendeeList(eventId, params) {
    return post(`projects/${eventId}/tickets/attendees-list?page=${params.page}&search=${params.search}`, { ticket: params.ticket });
}

export function checkInAttendees(eventId, attendeeId, data) {
    return post(`projects/${eventId}/attendees/${attendeeId}/bulk-check-in`, data);
}

export function checkInAttendee(eventId, attendeeId, data) {
    return post(`projects/${eventId}/attendees/${attendeeId}/check-in`, data);
}

export function checkOutAttendee(eventId, attendeeId, data) {
    return post(`projects/${eventId}/attendees/${attendeeId}/check-out`, data);
}

export function fetchCompanions(eventId, attendeeId, tickets) {
    return post(`projects/${eventId}/attendees/${attendeeId}/companions`, { tickets });
}

export function manageCompanionCheckIns(eventId, participantIds, tickets, checkedIn) {
    return post(`projects/${eventId}/manage/companions/check-in`, {
        participant_ids: participantIds,
        tickets,
        checked_in: checkedIn,
    });
}
